import $ from 'jquery';
import ScrollReveal from 'scrollreveal';
import SVG from 'svg.js';
import Chart from 'chart.js';

// ChartJS Globals

Chart.defaults.global.defaultFontSize = 18;
Chart.defaults.global.defaultFontFamily = 'mr-eaves-xl-modern, sans-serif';
Chart.defaults.global.defaultFontFamily = 'mr-eaves-xl-modern, sans-serif';

if (module.hot) {
  module.hot.accept();
}

// Circle animations
function animateCircles(el) {
	$('.pos-two').delay(180).animate({'opacity': 1}, 500);
	$('.pos-three').delay(360).animate({'opacity': 1}, 500);
	$('.pos-four').delay(540).animate({'opacity': 1}, 500);
	$('.pos-five').delay(720).animate({'opacity': 1}, 500);
	$('.pos-one').delay(900).animate({'opacity': 1}, 500);
}

// Bar Animations
function animateBars(bar) {
	var bar = $(bar);
	bar.each(function(index, bar) {
		var barEl = $(bar);
		var width = barEl.data('bar-width') + '%';
		barEl.css({
			'width': width,
			'opacity': '1',
			'padding': '0 10px',
		});
		setTimeout(function(){ 
			barEl.addClass('active');
		}, 1100);
		barEl.children().delay(1000).animate({'opacity': 1}, 500);
		barEl.siblings().delay(1000).animate({'opacity': 1}, 500);
	});
}
function animateParityLine(el) {
	$(el).find('.gender-parity-line__wrapper').delay(2000).animate({'opacity': 1}, 500);
}

function openPopups() {
	var openTriggers  = $('.transformative-impact__popup-link');

	openTriggers.each(function() {
		var showLink = $(this);
		$(this).click(function(e) {
			e.preventDefault();
			
			var container   = $(this).closest('.transformative-impact__popup-wrapper');
			var content     = $(this).siblings('.transformative-impact__popup-content');
			var backLink    = $(this).siblings('.transformative-impact__popup-back');
			var mainQuote   = $(this).siblings('blockquote').children('.transformative-impact__quote-main');
			var secondQuote = $(this).siblings('blockquote').children('.transformative-impact__quote-second');

			container.siblings().each(function() {
				$(this).hide();
			});

			if (secondQuote.length) {
				mainQuote.fadeOut(200, function() {
					secondQuote.fadeIn(200);
				});
			}

			container.hide().addClass('active').removeClass('col-sm-6 col-lg-4').fadeIn(300);
			
			$([document.documentElement, document.body]).animate({
		        scrollTop: $(container.parents('.transformative-impact__popups')).offset().top
		    }, 200);

			backLink.click(function(e) {
				e.preventDefault();

				container.siblings().each(function() {
					$(this).show();
				});

				if (secondQuote.length) {
					secondQuote.fadeOut(200, function() {
						mainQuote.fadeIn(200);
					});
				}
				
				container.hide().removeClass('col-12 active').addClass('col-12 col-lg-4').fadeIn(300);

				$([document.documentElement, document.body]).animate({
			        scrollTop: $(container.parents('.transformative-impact__popups')).offset().top
			    }, 200);

			});
		});
	});
}

function configureSvg(el) {
	var id              = $(el).data('number');
	var svgContainer    = $('#transformative-impact__svg-' + id);
	var largeCircle     = SVG.get('transformative-impact__circle--large-' + id);
	var smallCircle     = SVG.get('transformative-impact__circle--small-' + id);
	var gradient        = SVG.get('transformative-impact__circle--gradient-' + id);
	var largeCircleText = $('#transformative-impact__circle--large-text-' + id);
	var smallCircleText = $('#transformative-impact__circle--small-text-' + id);
	var gradientText    = $('#transformative-impact__circle--gradient-text-' + id);

	// largeCircleText.fadeOut(0);
	// smallCircleText.fadeOut(0);
	gradientText.fadeOut(0);

	gradient.width(1);

	// FadeOut Text
	largeCircle
		.size(208)
		.x(smallCircle.x())
		.y(smallCircle.y());

	// smallCircle
	// 	.scale(0.1);
}

function animateSvg(el) {
	var id              = $(el).data('number');
	var svgContainer    = $('#transformative-impact__svg-' + id);
	var largeCircle     = SVG.get('transformative-impact__circle--large-' + id);
	var smallCircle     = SVG.get('transformative-impact__circle--small-' + id);
	var gradient        = SVG.get('transformative-impact__circle--gradient-' + id);
	var largeCircleText = $('#transformative-impact__circle--large-text-' + id);
	var smallCircleText = $('#transformative-impact__circle--small-text-' + id);
	var gradientText    = $('#transformative-impact__circle--gradient-text-' + id);
	var captionTextLeft = $(el).siblings('.transformative-impact__diagram-info--left');
	var captionTextRight = $(el).siblings('.transformative-impact__diagram-info--right');

	svgContainer.addClass('show');
	// smallCircleText.fadeIn(100);
	captionTextLeft.animate({opacity:'1'});

	largeCircle
		.animate({ ease: '<', delay: '0.5s' })
		.size(252)
		.dmove(445, -22)
		.afterAll(function() {
			largeCircleText.fadeIn();
			captionTextRight.animate({opacity:'1'});
		});

	gradient
		.animate({ ease: '<', delay: '0.5s' })
		.width(492)
		.afterAll(function() {
			gradientText.delay(1000).fadeIn();
		});

}

function autoSizeCircles(className, minWidth) {
	if (window.innerWidth >= minWidth) {
		$(className).each(function() {
			$(this).height($(this).width());
		});
	} else {
		$(className).each(function() {
			$(this).css('height', '');
		});
	}
}

function caseStudyReveal(className) {
	$(className).each(function() {
		var caseStudy      = $(this);
		var excerpt        = caseStudy.find('.case-studies__excerpt');
		var viewMore       = caseStudy.find('.case-studies__gradient');
		var activeHeight   = caseStudy.height();
		var adjustedHeight = excerpt.offset().top - caseStudy.offset().top;
		
		// Set preview height
		caseStudy.height(adjustedHeight);

		viewMore.click(function(e) {
			e.preventDefault();
			caseStudy.height(activeHeight);
			caseStudy.css('height', '');
			viewMore.fadeOut();
		});

		// Adjust Heights on Resize
		$(window).resize(function() {
			if (viewMore.is(':visible')) {
				adjustedHeight = excerpt.offset().top - caseStudy.offset().top;
				caseStudy.height(adjustedHeight);
			}
		});
	});
}

function highlightCopy(el) {
	var copy = $(el);
	copy.css({
		'background-color': 'white',
		'color': copy.data('highlight-color')
	});
}

function createCharts() {
	var engineering = document.getElementById('case-study__doughnut-engineering');
	var operations  = document.getElementById('case-study__doughnut-operations');
	var strategy    = document.getElementById('case-study__doughnut-strategy');

	var caseStudyEngineering = new Chart(engineering, {
		type: 'doughnut',
		data: { 
			datasets: [{ 
				data: [59, 31],
				backgroundColor: [
					'rgba(70, 106, 166, 1)',
					'rgba(255, 255, 255, 1)',
				],
				borderColor: 'rgba(70, 106, 166, 1)',
				borderAlign: 'inner'
			}], 
			labels: [ 'Engineering' ]
		},
		options: {
			legend: {
				display: false
			},
			cutoutPercentage: 65,
			tooltips: {enabled: false},
		    hover: {mode: null},
		}
	});

	var caseStudyOperations = new Chart(operations, {
		type: 'doughnut',
		data: { 
			datasets: [{ 
				data: [67, 33],
				backgroundColor: [
					'rgba(70, 106, 166, 1)',
					'rgba(255, 255, 255, 1)',
				],
				borderColor: 'rgba(70, 106, 166, 1)',
				borderAlign: 'inner'
			}], 
			labels: [ 'Field & Plant Operations' ]
		},
		options: {
			legend: {
				display: false
			},
			cutoutPercentage: 65,
			tooltips: {enabled: false},
		    hover: {mode: null},
		}
	});

	var caseStudyStrategy = new Chart(strategy, {
		type: 'doughnut',
		data: { 
			datasets: [{ 
				data: [50, 50],
				backgroundColor: [
					'rgba(70, 106, 166, 1)',
					'rgba(255, 255, 255, 1)',
				],
				borderColor: 'rgba(70, 106, 166, 1)',
				borderAlign: 'inner'
			}], 
			labels: [ 'Corporate Strategy' ]
		},
		options: {
			legend: {
				display: false
			},
			cutoutPercentage: 65,
			tooltips: {enabled: false},
		    hover: {mode: null},
		}
	});
}

function caseStudyPopups() {
	var popupTriggers = $('.case-studies__circle a');
	var popupExit = $('.case-studies__popup a');

	var circlesLeftParent = popupTriggers.closest('.case-studies__circles--left');
	var circlesRightParent = popupTriggers.closest('.case-studies__circles--right');
	var studyWrapperParent = popupTriggers.closest('.case-studies__study-wrapper');

	popupTriggers.click(function(e) {
		e.preventDefault();
		var link = $(this);

		if (link.attr('href').length > 1) {
			var popup = $(link.attr('href'));
			popup.fadeIn(200).siblings('.case-studies__popup').hide();
			circlesLeftParent.animate({'opacity': 0.2});
			circlesRightParent.animate({'opacity': 0.2});
			studyWrapperParent.animate({'opacity': 0.2});

			$([document.documentElement, document.body]).animate({
		        scrollTop: $(link.attr('href')).offset().top - 150
		    }, 200);
		}
	});

	popupExit.click(function(e) {
		e.preventDefault();
		$(this).parent().fadeOut(200);
		circlesLeftParent.animate({'opacity': 1});
		circlesRightParent.animate({'opacity': 1});
		studyWrapperParent.animate({'opacity': 1});
	});
}

function callForActionPopups() {
	var popupTriggers = $('.call-for-action__links a');
	var popupExit = $('.call-for-action__popup a');

	popupTriggers.click(function(e) {
		e.preventDefault();
		var link = $(this);

		if (link.attr('href').length > 1) {
			var popup = $(link.attr('href'));
			popupTriggers.animate({'opacity': 0.2})
			popup.fadeIn(200).siblings('.call-for-action__popup').hide();
			
			$([document.documentElement, document.body]).animate({
		        scrollTop: $(link.attr('href')).offset().top - 150
		    }, 200);
		}
	});

	popupExit.click(function(e) {
		e.preventDefault();
		$(this).parent().fadeOut(200);
		popupTriggers.animate({'opacity': 1});
	});
}

function stickyHeaders() {
   $(".case-studies__study-container").each(function() {
       	var el             = $(this);
    	var offset         = el.offset();
        var scrollTop      = $(window).scrollTop();
        var stickyHeader   = $('.case-studies__headline', this);

		if ((scrollTop > offset.top) && (scrollTop <= offset.top + el.height())) {
			$(this).css('padding-top',stickyHeader.outerHeight());
			stickyHeader.addClass('fixed')
		} else {
			$(this).css('padding-top','');
			stickyHeader.removeClass('fixed'); 
       };
   });
}

$(window).bind('scroll', function(){
	stickyHeaders();
});

$(document).ready(function() {
	autoSizeCircles('.case-studies__circle', 0);

	// Instantiate circle animations
	ScrollReveal().reveal( '.circle-diagram', { 
		afterReveal: animateCircles, 
		delay: 250, 
		viewOffset: {
			bottom: 200
		}
	} );
	// Instantiate bar animations
	ScrollReveal().reveal( document.querySelectorAll('.blue-bar-chart [data-bar-width]'), { 
		afterReveal: animateBars
	} );
	ScrollReveal().reveal( document.querySelectorAll('.orange-bar-chart [data-bar-width]'), { 
		afterReveal: animateBars
	} );
	ScrollReveal().reveal( document.querySelectorAll('.orange-bar-chart .detailed-bar-chart__wrapper-inner'), { 
		afterReveal: animateParityLine
	} );
	ScrollReveal().reveal( document.querySelectorAll('.red-and-blue-bar-chart [data-bar-width]'), { 
		afterReveal: animateBars,
		viewOffset: {
			bottom: 200
		}
	} );
	ScrollReveal().reveal( document.querySelectorAll('.red-and-blue-bar-chart .detailed-bar-chart__wrapper-inner'), { 
		afterReveal: animateParityLine,
		viewOffset: {
			bottom: 200
		}
	} );
	ScrollReveal().reveal( document.querySelectorAll('.gender-diversity-chart [data-bar-width]'), { 
		afterReveal: animateBars,
		//delay: 500, 
		viewOffset: {
			bottom: 200
		}
	} );
	ScrollReveal().reveal( '.transformative-impact__svg-wrapper', { 
		beforeReveal: configureSvg,
		afterReveal: animateSvg, 
		delay: 250
	} );
	ScrollReveal().reveal( '.case-studies__study-wrapper p [data-highlight-color]', { 
		afterReveal: highlightCopy,
	} );
	ScrollReveal().reveal( document.querySelectorAll('.case-studies__dl:not(.case-studies__dl--no-anim) li'), { 
		interval: 450,
		origin: 'left',
		distance: '500px',
		viewOffset: {
			bottom: 150
		}
	} );
	ScrollReveal().reveal( document.querySelectorAll('.case-studies__circle-def'), { 
		interval: 200,
		origin: 'left',
		distance: '500px',
		viewOffset: {
			bottom: 150
		}
	} );

	// Add listeners for popup links [data-popup-id]
	openPopups();

	// Case study read more 
	caseStudyReveal('.case-studies__study');

	// Create ChartJs charts
	createCharts();

	// Case Study Circle Popups
	caseStudyPopups();

	// Call for Action Popups
	callForActionPopups();

});

$(window).resize(function() {
	autoSizeCircles('.case-studies__circle', 0);
});
